import { AlpineComponent } from 'wly-statamic-theme-extensions';
import axios from 'axios';

const http = axios.create({
    baseURL: '/',
    responseType: 'json',
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json;charset=UTF-8',
    },
    transformRequest: [
        function (data, headers) {
            if (data instanceof FormData) {
                headers.setContentType('multipart/form-data');
            }

            return data;
        },
    ],
});

http.interceptors.response.use(
    (response) => response,
    (error) => {
        return Promise.reject(error.response);
    }
);

export class FormComponent extends AlpineComponent {
    public submitting: boolean = false;
    public success: boolean = false;
    public errors: any = {};

    init() {
        this.errors = {};
        this.success = false;
        this.submitting = false;

        this.$nextTick(() => {
            window.runReCaptchaCallback && window.runReCaptchaCallback((grecaptcha) => {
                grecaptcha.render(this.$el.querySelector('.g-recaptcha'));
            });

            window.runCfTurnstileCallback && window.runCfTurnstileCallback((turnstile) => {
                turnstile.render(this.$el.querySelector('.cf-turnstile'));
            });
        });
    }

    submit(e: Event) {
        e.preventDefault();

        const form = e.target as HTMLFormElement;
        const data = new FormData(form);

        this.submitting = true;

        http.request({
            url: form.getAttribute('action') || '',
            method: form.getAttribute('method') || '',
            data,
        })
            .then((res) => {
                this.success = true;

                form.dispatchEvent(new CustomEvent('success'));
            })
            .catch((err: any) => {
                if (err.status === 500) {
                    alert('Leider konnte das Formular nicht abgeschickt werden. Bitte senden Sie uns direkt eine Mail.');
                } else if (err.status === 413) {
                    alert('Die ausgewählten Dateien sind zu gross. Bitten wählen Sie kleinere Dateien aus.');
                } else {
                    this.errors = err.data.error;

                    if (this.errors?.captcha) {
                        window.runReCaptchaCallback((grecaptcha) => {
                            grecaptcha.reset(this.$el.querySelector('.g-recaptcha'));
                        });

                        window.runCfTurnstileCallback((turnstile) => {
                            turnstile.reset(this.$el.querySelector('.cf-turnstile'));
                        });
                    }
                }
            })
            .finally(() => {
                this.submitting = false;
            });
    }
}
