import { RunThemeExtensions } from 'wly-statamic-theme-extensions';
import './scripts/index';
import { FixedHeaderComponent } from '@/alpine/components/FixedHeaderComponent';
import { FormComponent } from '@/alpine/components/FormComponent';

RunThemeExtensions({
    alpine: {
        components: {
            FixedHeader: FixedHeaderComponent,
            Form: FormComponent,
        },
    },
});
