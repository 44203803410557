import { AlpineComponent } from 'wly-statamic-theme-extensions';

export class FixedHeaderComponent extends AlpineComponent {
    public fixed: boolean = false;
    public height: number = 0;
    public offset: number = 0;

    shouldBeFixed() {
        return this.offset < window.scrollY;
    }

    init() {
        this.offset = this.$refs.bottom?.offsetTop ?? 0;
        this.height = this.$refs.bottom?.clientHeight ?? 0;

        const placeholder = this.$refs?.placeholder;

        if (placeholder) {
            placeholder.style.height = this.height + 'px';
        }

        window.addEventListener('scroll', () => {
            this.fixed = this.shouldBeFixed();
        });
    }
}
